import Logo from './assets/svgs/Logo';

function App() {
  return (
    <div style={{ width: '400px', height: '400px' }}>
      <Logo />
    </div>
  );
}

export default App;
